.SmallHeader {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #d53a3d;
}

.SmallHeader .title {
  left: 0px;
  right: 0px;
  height: 100%;
  font-size: 40px;
  padding: 0px 20px;
  vertical-align: bottom;
  font-weight: 700;
  text-align: right;
  color: #d53a3d;
}
