.Ranking .header {
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #d53a3d;
}

.Ranking .header .logo {
  margin-left: 20px;
  margin-top: 12px;
  width: 300px;
  height: 100%;
  background-image: url("images/logoleft.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.Ranking .header .logo,
.Ranking .header .title,
.Ranking .header .subtitle {
  position: absolute;
  left: 0px;
  right: 0px;
  height: 100%;
  font-size: 40px;
  padding: 0px 20px;
  vertical-align: bottom;
  font-weight: 700;
}

.Ranking .header .title,
.Ranking .header .subtitle {
  margin-top: 48px;
}

.Ranking .header .title {
  text-align: center;
}

.Ranking .header .subtitle {
  text-align: right;
  color: #d53a3d;
}

.Ranking .scoreboard {
  margin: 20px;
  font-size: 30px;
}

.Ranking .scoreboard .scoreboardheader,
.Ranking .scoreboard .player {
  clear: both;
  border-bottom: 1px solid #e2b75c;
  height: 42px;
  margin-top: 2px;
  font-weight: 400;
}

.Ranking .scoreboard .scoreboardheader {
  font-weight: bold;
}

.Ranking .playersContainer {
  overflow: hidden; /* Needs this as the content inside it scrolls */
}

.Ranking .scoreboard .players {
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}

