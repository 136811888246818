.LiveUpdates .scoreboard {
  margin: 20px;
  font-size: 30px;
  white-space: nowrap;
}

.LiveUpdates .scoreboard .scoreboardheader,
.LiveUpdates .scoreboard .player {
  clear: both;
  border-bottom: 1px solid #e2b75c;
  height: 42px;
  margin-top: 2px;
}

.LiveUpdates .scoreboard .scoreboardheader {
  font-weight: bold;
}

.LiveUpdates .scoreboard .player {
  font-weight: 400;
}

.LiveUpdates .scoreboard .swimIcon,
.LiveUpdates .scoreboard .t1Icon,
.LiveUpdates .scoreboard .bikeIcon,
.LiveUpdates .scoreboard .t2Icon,
.LiveUpdates .scoreboard .runIcon {
  display: inline-block;
  background-size: contain;
  width: 40px;
  height: 40px;
  margin: 0px 4px;
}

.LiveUpdates .scoreboard .swimIcon {
  background-image: url("images/swimming.png");
}

.LiveUpdates .scoreboard .t1Icon {
  background-image: url("images/change.png");
}

.LiveUpdates .scoreboard .bikeIcon {
  background-image: url("images/bike.png");
}

.LiveUpdates .scoreboard .t2Icon {
  background-image: url("images/change.png");
}

.LiveUpdates .scoreboard .runIcon {
  background-image: url("images/running.png");
}

.icons {
  white-space: nowrap;
  overflow: hidden;
}

.LiveUpdates .SmallHeader .title {
  text-align: center;
  color: #323957;
}

.liveheader {
  background-color: #323957 !important;
  padding: 0px;
  border-radius: 0px;
  box-shadow: none;
}

.liveheader .nav-link.active {
  background-color: #d53a3d;
}

@media screen and (max-width: 1284px) {
  .nav-link {
    padding: 12px;
    font-size: 15px;
  }

  .LiveUpdates .SmallHeader {
    height: 30px;
  }

  .LiveUpdates .SmallHeader .title {
    font-size: 20px;
    padding: 0px 15px;
  }

  .LiveUpdates .scoreboard {
    margin: 10px;
    font-size: 15px;
  }

  .LiveUpdates .scoreboard .scoreboardheader,
  .LiveUpdates .scoreboard .player {
    height: 21px;
    margin-top: 1px;
  }

  .LiveUpdates .scoreboard .swimIcon,
  .LiveUpdates .scoreboard .t1Icon,
  .LiveUpdates .scoreboard .bikeIcon,
  .LiveUpdates .scoreboard .t2Icon,
  .LiveUpdates .scoreboard .runIcon {
    display: inline-block;
    background-size: contain;
    width: 20px;
    height: 20px;
    margin: 0px 2px;
  }
}
@media screen and (max-width: 590px) {
  .nav-link {
    padding: 5px;
    font-size: 14px;
  }

  .LiveUpdates .scoreboard .scoreboardheader,
  .LiveUpdates .scoreboard .player {
    height: 16px;
    margin-top: 1px;
  }

  .LiveUpdates .scoreboard .swimIcon,
  .LiveUpdates .scoreboard .t1Icon,
  .LiveUpdates .scoreboard .bikeIcon,
  .LiveUpdates .scoreboard .t2Icon,
  .LiveUpdates .scoreboard .runIcon {
    display: inline-block;
    background-size: contain;
    width: 14px;
    height: 14px;
    margin: 0.5px 1px;
  }

  .LiveUpdates .scoreboard {
    font-size: 10px;
  }
}

@media screen and (max-width: 360px) {
  .nav-link {
    padding: 3px;
    font-size: 13px;
  }

  .LiveUpdates .scoreboard .scoreboardheader,
  .LiveUpdates .scoreboard .player {
    height: 13px;
    margin-top: 1px;
  }


  .LiveUpdates .scoreboard .swimIcon,
  .LiveUpdates .scoreboard .t1Icon,
  .LiveUpdates .scoreboard .bikeIcon,
  .LiveUpdates .scoreboard .t2Icon,
  .LiveUpdates .scoreboard .runIcon {
    display: inline-block;
    background-size: contain;
    width: 12px;
    height: 12px;
    margin: 0px 0.5px;
  }

  .LiveUpdates .scoreboard {
    font-size: 8px;
  }
}
