* {
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
@font-face {
  font-family: "Gedau";
  src: url("fonts/TLMGedau/TLMGedauRoman.eot"), url("fonts/TLMGedau/TLMGedauRoman.woff") format('woff'), url("fonts/TLMGedau/TLMGedauRoman.svg") format('svg');
}
html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Disable horizontal scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
}
body {
  background: #f2f2f2;
  font-family: "Gedau", sans-serif;
}
body {
  background-color: #fff;
  color: #323957;
  font-family: 'Roboto', sans-serif;
/*  cursor: none;*/
  white-space: nowrap;
}
